<template>
  <div class="lazy-container pt-5">
    <Header :required="true" :title="'Assets For Verification'" subtitle="These are the assets that need your approval"></Header>
    <div class="mt-10 lazy-asset pl-5">
      <v-row>
        <v-col lg="4" v-for="(token, index) in tokens" :key="index">
          <token-card-new :data="token"></token-card-new>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import { mapState } from "vuex";
import { assetObj } from "../services/asset-service";
import TokenCardNew from "../components/Common/TokenCardNew.vue";

export default {
  name: "LazyAssets",
  components: {
    Header,
    TokenCardNew,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      tokens: [],
    };
  },
  created() {
    this.getAllUnVerifiedAssets();
  },
  methods: {
    async getAllUnVerifiedAssets() {
      // if (window.ethereum) {
      //   const response = await assetObj.getAllNonVerifiedAssets();
      //   this.tokens = response;
      // } else {
        const response = await assetObj.getAllLazyMintingAssetByStatus(false);
        this.tokens = response;
      // }
    },
  },
};
</script>
<style scoped>
.lazy-container {
  width: 100%;
    background-image: url("../assets/hue.svg");
  /* height: 100%; */
  margin-bottom: 10%;
  min-height: 100vh;
}

.lazy-asset {
  width: 100%;
}
</style>
